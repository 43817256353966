import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { MaterialCalculation } from 'src/app/models/material-code';
import { MetalMarket, MetalMarketPremiums } from 'src/app/models/metal-market';
import { MaterialCodeService } from 'src/app/services/material-code.service';
import { MetalMarketService } from 'src/app/services/metal-market.service';

const BASE_METAL = {
  gold: 1,
  fairminedGold: 2,
  platinum: 3,
  notApplicable: 4,
};

@Component({
  selector: 'app-metal-overhead-equations',
  templateUrl: './metal-overhead-equations.component.html',
  styleUrls: ['./metal-overhead-equations.component.scss'],
})
export class MetalOverheadEquationsComponent implements OnInit {
  constructor(
    private metalMarketService: MetalMarketService,
    private materialCodeService: MaterialCodeService,
  ) {}

  displayedColumns = [
    'description',
    'baseMetal',
    'preciousPercentage',
    'alloyCost',
    'lossFactor',
    'pricePerGram',
  ];
  metalMarketPremiums: MetalMarketPremiums = {} as MetalMarketPremiums;
  metalMarket: MetalMarket = {} as MetalMarket;
  materialCalculations: MaterialCalculation[] = [];
  baseMetal = BASE_METAL;
  isSaving = false;
  isLoading = false;

  ngOnInit(): void {
    this.getMetalMarketPremiums();
  }

  getMetalMarketPremiums() {
    this.isLoading = true;
    forkJoin([
      this.metalMarketService.getLatestMetalMarket(),
      this.metalMarketService.getMetalMarketPremiums(),
    ]).subscribe(([metalMarket, [premiums]]) => {
      this.metalMarketPremiums = premiums;
      this.metalMarket = metalMarket;
      this.isLoading = false;
      this.getMaterialCodeCalculations();
    });
  }

  getMaterialCodeCalculations() {
    forkJoin([
      this.materialCodeService.getList(),
      this.materialCodeService.getMaterialCodeCalculations(),
    ]).subscribe(
      ([{ responseObject: materialCodes }, materialCodeCalculations]) => {
        this.materialCalculations = materialCodes.reduce((accum, m) => {
          if (m.multiMetal) return accum;
          const calculation = materialCodeCalculations.find(
            (c) => c.materialCodeID === m.materialCodeId,
          );
          return [
            ...accum,
            {
              ...m,
              ...(calculation ?? {}),
            },
          ];
        }, []);
      },
    );
  }

  setMetalMarketPremiums() {
    this.isSaving = true;
    this.metalMarketService
      .setMetalMarketPremiums(this.metalMarketPremiums)
      .subscribe(() => {
        this.isSaving = false;
      });
  }

  getPricePerGram(data: MaterialCalculation) {
    if (!data.baseMetal || data.baseMetal === BASE_METAL.notApplicable)
      return 0;
    let metalMarketValue = 0;
    let premium = 0;

    switch (data.baseMetal) {
      case BASE_METAL.gold:
        metalMarketValue = this.metalMarket.ldpmGold;
        premium = this.metalMarketPremiums.gold;
        break;
      case BASE_METAL.fairminedGold:
        metalMarketValue = this.metalMarket.ldpmGold;
        premium = this.metalMarketPremiums.fairminedGold;
        break;
      case BASE_METAL.platinum:
        metalMarketValue = this.metalMarket.ldpmPlatinum;
        premium = this.metalMarketPremiums.platinum;
        break;
    }
    const preciousPercentage = data.preciousPercentage ?? 0;
    const alloyCost = data.alloyCost ?? 0;
    const lossFactor = data.lossFactor ?? 0;
    return (
      (((metalMarketValue + premium) * preciousPercentage) / 31.1035 +
        alloyCost) *
      lossFactor
    );
  }

  onSaveCalculations() {
    const request = this.materialCalculations.reduce((accum, calculation) => {
      const hasValue =
        (calculation.baseMetal ?? '') !== '' &&
        (calculation.preciousPercentage ?? '') !== '' &&
        (calculation.alloyCost ?? '') !== '' &&
        (calculation.lossFactor ?? '') !== '';

      if (hasValue) {
        return [
          ...accum,
          this.materialCodeService.setMaterialCodeCalculation({
            materialCodesCalculationsID:
              calculation.materialCodesCalculationsID ?? 0,
            materialCodeID: calculation.materialCodeId,
            baseMetal: calculation.baseMetal,
            preciousPercentage: calculation.preciousPercentage,
            alloyCost: calculation.alloyCost,
            lossFactor: calculation.lossFactor,
          }),
        ];
      } else if (calculation.materialCodesCalculationsID > 0) {
        return [
          ...accum,
          this.materialCodeService.deleteMaterialCodeCalculation(
            calculation.materialCodesCalculationsID,
          ),
        ];
      }
      return accum;
    }, []);
    this.isSaving = true;
    forkJoin(request).subscribe(() => {
      this.isSaving = false;
      this.getMaterialCodeCalculations();
    });
  }
}

<div class="riva-reporting-work-order-movement">
  <div class="tab-container">
    <nz-tabset nzType="card">
      <nz-tab nzTitle="Fairmined Invoice">
        <app-invoice-reports></app-invoice-reports>
      </nz-tab>
      <nz-tab nzTitle="Stone Movement"></nz-tab>
    </nz-tabset>
  </div>
</div>

<div class="invoice-reports-page">
  <div class="filters">
    <riva-date-range-picker
      [startDate]="filters.dateRangeFrom"
      [endDate]="filters.dateRangeTo"
      (onChange)="onDateRangeChange($event)"
    ></riva-date-range-picker>
  </div>
  <div class="invoice-table">
    <table
      mat-table
      [dataSource]="invoices"
      matSort
      matSortActive="invoicesID"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="invoicesID">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          start="desc"
          sortActionDescription="Sort by invoicesID"
        >
          Invoice #
        </th>
        <td mat-cell *matCellDef="let element">
          <a
            class="riva-button riva-button-sm riva-button-link"
            [routerLink]="element.link"
            target="_blank"
            style="font-size: 14px"
          >
            {{ element.invoicesId }}
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="1">No Available Data</td>
      </tr>
    </table>
  </div>
</div>

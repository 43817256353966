<div class="riva-chain">
  <div class="chain-finish-detail">
    <div>
      <div class="riva-chain-finish-list__header">
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 10px">
          <mat-form-field
            class="search-input"
            appearance="fill"
            style="width: 400px"
          >
            <mat-label>Search</mat-label>
            <input
              matInput
              style="color: white"
              [(ngModel)]="search"
              (ngModelChange)="onFilterChange()"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%">
            <mat-label>Chain Style</mat-label>
            <mat-select
              [(ngModel)]="filterChainStyleID"
              (ngModelChange)="onFilterChange()"
            >
              <mat-option
                *ngFor="let style of chainStyles"
                [value]="style.chainStylesID"
              >
                {{ style.styleName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="action-buttons">
          <button
            class="btn btn-success"
            style="height: 45px; margin-right: 8px"
            (click)="onOpenGenerateSkuDialog()"
          >
            Generate SKU
          </button>
          <button
            type="button"
            class="riva-button"
            style="width: 250px; justify-self: flex-end"
            (click)="createNewChain()"
          >
            New Chain
          </button>
        </div>
      </div>
      <div class="mat-table__container">
        <table
          mat-table
          matSort
          [dataSource]="chains"
          matSortActive="chainRawID"
          matSortDirection="desc"
        >
          <ng-container matColumnDef="sku">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by sku"
            >
              Name
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.sku }}
            </td>
          </ng-container>
          <ng-container matColumnDef="styleName">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by styleName"
            >
              Style
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.styleName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="linkDim1">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by linkDim1"
            >
              Link Dim 1
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.linkDim1 }}<span *ngIf="element.linkDim1">mm</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="linkDim2">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by linkDim2"
            >
              Link Dim 2
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.linkDim2 }}<span *ngIf="element.linkDim2">mm</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="wireSize">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by wireSize"
            >
              Wire Size
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.wireSize }}<span *ngIf="element.wireSize">mm</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              start="asc"
              sortActionDescription="Sort by description"
            >
              Description
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="riva-chain-row"
            [ngClass]="{
              'is-selected': row.chainRawID === selectedChain?.chainRawID
            }"
            (click)="onSelectChain(row)"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell text-center" colspan="6">No Available Data</td>
          </tr>
        </table>
      </div>
    </div>
    <div>
      <div>
        <div>
          <input
            type="file"
            #uploadImage
            (change)="uploadChainRawImage(uploadImage.files)"
            style="display: none"
          />
          <button
            class="riva-button"
            (click)="uploadImage.click()"
            [disabled]="!editMode || !selectedChain.chainRawID"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px 6px 0 0;
            "
          >
            <i
              nz-icon
              nzType="file-image"
              nzTheme="outline"
              style="margin-right: 8px"
            ></i>
            Upload Picture
          </button>
        </div>
        <div class="image-wrapper">
          <div class="image-container">
            <img
              [src]="selectedChain?.picPath"
              onerror="this.src='assets/images/no-image.png'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div></div>

  <div class="chain-finish-other">
    <div class="other-group-one">
      <div class="editable-fields">
        <mat-form-field appearance="fill">
          <mat-label>Name</mat-label>
          <input
            matInput
            [(ngModel)]="selectedChain.sku"
            (blur)="onUpdateSelectedChain()"
            [disabled]="!editMode"
            style="color: white"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Link Dim 1 (mm)</mat-label>
          <input
            matInput
            [(ngModel)]="selectedChain.linkDim1"
            (blur)="onUpdateSelectedChain()"
            type="number"
            max="999.99"
            [disabled]="!editMode"
            style="color: white"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Link Dim 2 (mm)</mat-label>
          <input
            matInput
            [(ngModel)]="selectedChain.linkDim2"
            (blur)="onUpdateSelectedChain()"
            type="number"
            max="999.99"
            [disabled]="!editMode"
            style="color: white"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Wire Size (mm)</mat-label>
          <input
            matInput
            [(ngModel)]="selectedChain.wireSize"
            (blur)="onUpdateSelectedChain()"
            type="number"
            max="999.99"
            [disabled]="!editMode"
            style="color: white"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" style="grid-column: 1 / span 2">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            [(ngModel)]="selectedChain.description"
            (blur)="onUpdateSelectedChain()"
            [disabled]="!editMode"
            style="height: 42px; color: white"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="size-and-material">
        <div>
          <label style="color: white">Materials</label>
          <div class="size-table">
            <div
              *ngFor="let item of chainStocks"
              class="mat-row"
              [ngClass]="{
                'mat-size':
                  selectedChainStock.chainRawStockID === item.chainRawStockID
              }"
              (click)="onSelectStock(item)"
            >
              <div style="display: flex; align-items: center">
                <div
                  style="
                    width: 25px;
                    height: 18px;
                    border: 1px solid white;
                    background-color: rgb(0, 0, 0);
                    margin-right: 5px;
                  "
                  [ngStyle]="{
                    'background-color': '#' + item.material?.printColor1
                  }"
                ></div>
                {{ item.material?.description }}
              </div>
            </div>
          </div>
          <div style="margin-top: 10px" *ngIf="editMode">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Material Code</mat-label>
              <mat-select [(ngModel)]="selectedMaterialCode">
                <mat-option
                  *ngFor="let mats of filteredMaterialCodes"
                  [value]="mats.materialCodeId"
                >
                  {{ mats.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button
              class="riva-button riva-button-sm"
              style="width: 100%"
              [disabled]="!selectedMaterialCode || isStockSaving"
              (click)="onSaveChainStock(true)"
            >
              Add Material
            </button>
          </div>
        </div>
        <div class="stock-fields">
          <mat-form-field appearance="fill">
            <mat-label>Inches in Stock</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="selectedChainStock.qtyInStockInch"
              style="color: white"
              [disabled]="!editMode"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>Grams Per Inch</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="selectedChainStock.weightPerInchG"
              max="999.999"
              style="color: white"
              [disabled]="!editMode"
            />
          </mat-form-field>
          <button
            *ngIf="editMode"
            class="btn btn-primary"
            style="font-size: 12px; padding: 5px; height: 40px"
            (click)="onSaveChainStock()"
            [disabled]="isStockSaving || !isStockFormValid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="chain-action-button">
      <button
        type="button"
        class="riva-button"
        style="width: 100%; justify-self: flex-end"
        (click)="onSetEditMode()"
        [ngClass]="{ hidden: editMode }"
      >
        Edit
      </button>
      <button
        type="button"
        class="btn btn-success"
        style="width: 100%; justify-self: flex-end; text-transform: unset"
        (click)="onSetEditMode()"
        [ngClass]="{ hidden: !editMode }"
      >
        Save
      </button>
      <button
        type="button"
        class="riva-button"
        style="width: 100%; justify-self: flex-end"
        (click)="onRoutingDialogOpen()"
      >
        Routing
      </button>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';

import { LoadService } from '../load-overlay/load-overlay.service';

import { forkJoin, Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { TokenStorageService } from 'src/app/core/authentication/token-storage.service';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { MetalMarket, MetalMarketPremiums } from 'src/app/models/metal-market';
import { MetalMarketService } from 'src/app/services/metal-market.service';
import { menus } from './menu';

@Component({
  selector: 'riva-side-nav',
  templateUrl: './riva-side-nav.component.html',
  styleUrls: ['riva-side-nav.component.scss'],
})
export class RivaSideNavComponent implements OnInit {
  isCollapsed = true;
  menus = menus;
  menuWithPermission = [];

  isLoggedIn$: Observable<boolean>;
  showSideNav = true;
  metalMarket: MetalMarket;
  metalMarketPremiums: MetalMarketPremiums;
  isLoadingMetalMarket = false;
  popoverTrigger = null;

  constructor(
    private authenticationService: AuthenticationService,
    private loadService: LoadService,
    private userPermissionService: UserPermissionService,
    private metalMarketService: MetalMarketService,
    public tokenStorageService: TokenStorageService,
  ) {
    if (tokenStorageService.currentUser.usersID) {
      this.authenticationService.loggedIn.next(true);
    }
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;
  }

  get loggedUser(): string {
    if (this.isLoggedIn$) return this.tokenStorageService.currentUser.userName;
  }

  ngOnInit(): void {
    this.loadService.reloadNavigation = () => {
      this.menuWithPermission = this.getMenuWithPermission();
    };
    this.menuWithPermission = this.getMenuWithPermission();
    this.isLoadingMetalMarket = true;
  }

  getMenuWithPermission() {
    const currentUser = this.tokenStorageService.currentUser;
    if (!currentUser || !currentUser.usersID) return [];
    forkJoin([
      this.metalMarketService.getLatestMetalMarket(),
      this.metalMarketService.getMetalMarketPremiums(),
    ]).subscribe(([metalMarket, [premiums]]) => {
      this.metalMarket = metalMarket;
      this.metalMarketPremiums = premiums;
      this.popoverTrigger = 'hover';
      this.isLoadingMetalMarket = false;
    });
    if (currentUser.isAdmin) return [...menus];

    if (!this.userPermissionService.userPagePermission) return;
    return this.menus.reduce((menuWithPermission, menu) => {
      if (!menu.subMenu) {
        const { pageName } = menu;
        const { accessLevel, hasCustomAccess, disabled } = this
          .userPermissionService.userPagePermission[pageName] ?? {
          accessLevel: 'Disabled',
          disabled: false,
        };
        const hasAccess =
          accessLevel && accessLevel !== 'Disabled' && hasCustomAccess;
        return disabled == null || hasAccess
          ? [...menuWithPermission, menu]
          : menuWithPermission;
      }
      const subMenu = menu.subMenu.reduce((subMenuWithPermissions, subMenu) => {
        const { pageName } = subMenu;
        const { accessLevel, disabled, hasCustomAccess } = this
          .userPermissionService.userPagePermission[pageName] ?? {
          accessLevel: 'Disabled',
          disabled: false,
        };

        const hasAccess =
          accessLevel && accessLevel !== 'Disabled' && hasCustomAccess;
        return disabled == null || hasAccess
          ? [...subMenuWithPermissions, subMenu]
          : subMenuWithPermissions;
      }, []);
      return [...menuWithPermission, { ...menu, subMenu }];
    }, []);
  }

  logout() {
    this.authenticationService.signOut();
  }
}

export class MaterialCode {
  materialCodeId: number = 0;
  code: string = '';
  karat: string = '';
  color: string = '';
  description: string = '';
  printColor1?: string = '';
  multiMetal?: boolean;
  multiMetalPrimary?: number;
  multiMetalPrimaryMaterial?: MaterialCode;
  multiMetalSecondary?: number;
  multiMetalSecondaryMaterial?: MaterialCode;
}

export class MatCodeSize {
  productID: number;
  matID: number;
  size: number;
  uom: number;
  qtyStock: number;
  wtgr: number;
  whlsprice: number;
  msrp: number;
}

export class Sizes {
  size: number;
}

export class SizeUpdate {
  materialCodeId: number;
  oldSize: number;
  newSize: number;
}

export interface MaterialCodeCalculation {
  materialCodesCalculationsID: number;
  materialCodeID: number;
  baseMetal: number;
  preciousPercentage: number;
  alloyCost: number;
  lossFactor: number;
}

export interface MaterialCalculation
  extends MaterialCode,
    MaterialCodeCalculation {}

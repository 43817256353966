import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';
import { Observable, forkJoin } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/components/riva-confirmation/riva-confirmation.service';
import {
  RivaGems,
  StoneClarity,
  StoneColor,
  StoneCut,
  StoneSize,
  StoneType,
} from '../../riva-gems.model';
import { StoneService } from '../../stone.service';
import { StoneConfirmationComponent } from '../stone-confirmation/stone-confirmation.component';

@Component({
  templateUrl: './stone-dialog.component.html',
  styleUrls: ['./stone-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StoneDialogComponent implements OnInit {
  isSaving = false;
  sizeBorderClass = '';
  gem: RivaGems = { stockQTY: 0 } as RivaGems;
  gems: RivaGems[] = [];
  stoneSizes: StoneSize[];
  stoneTypes: StoneType[];
  stoneCuts: StoneCut[];
  stoneClarities: StoneClarity[];
  stoneColors: StoneColor[];
  filteredTypes: Observable<StoneType[]>;
  filteredCuts: Observable<StoneCut[]>;
  filteredClarities: Observable<StoneClarity[]>;
  filteredColors: Observable<StoneColor[]>;
  filteredSizesD1: Observable<StoneSize[]>;
  filteredSizesD2: Observable<StoneSize[]>;
  filteredSizesD3: Observable<StoneSize[]>;

  stoneTypeControl = new FormControl('');
  stoneCutControl = new FormControl('');
  stoneClarityControl = new FormControl('');
  stoneColorControl = new FormControl('');
  stoneSizeD1Control = new FormControl('');
  stoneSizeD2Control = new FormControl('');
  stoneSizeD3Control = new FormControl('');

  currentStonePicture = '';
  isSizeOrderValid = true;

  @ViewChild('confirmationDuplicateStone')
  public readonly confirmationDuplicateStone!: SwalComponent;

  constructor(
    public dialogRef: MatDialogRef<StoneDialogComponent>,
    public dialog: MatDialog,
    private stoneService: StoneService,
    private _confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.stoneService.getStones().subscribe((stones) => {
      this.gems = stones;
    });
    forkJoin([
      this.stoneService.getSizes(),
      this.stoneService.getTypes(),
      this.stoneService.getCuts(),
      this.stoneService.getClarities(),
      this.stoneService.getColors(),
    ]).subscribe(([stones, types, cuts, clarities, colors]) => {
      this.stoneSizes = orderBy(stones, [(item) => item.name?.toLowerCase()]);
      this.stoneTypes = orderBy(types, [(item) => item.name?.toLowerCase()]);
      this.stoneCuts = orderBy(cuts, [(item) => item.name?.toLowerCase()]);
      this.stoneClarities = orderBy(clarities, [
        (item) => item.name?.toLowerCase(),
      ]);
      this.stoneColors = orderBy(colors, [
        (item) => item.colorName?.toLowerCase(),
      ]);
      this.filteredTypes = this.stoneTypeControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name
            ? this._filterType(name as string)
            : this.stoneTypes.slice();
        }),
      );
      this.filteredCuts = this.stoneCutControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name
            ? this._filterCut(name as string)
            : this.stoneCuts.slice();
        }),
      );
      this.filteredClarities = this.stoneClarityControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.name;
          return name
            ? this._filterClarity(name as string)
            : this.stoneClarities.slice();
        }),
      );
      this.filteredColors = this.stoneColorControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : value?.colorName;
          return name
            ? this._filterColor(name as string)
            : this.stoneColors.slice();
        }),
      );
    });
  }

  getControlValue(ctrl: FormControl, key: string) {
    return typeof ctrl.value === 'string' ? 0 : ctrl.value[key];
  }
  onSaveStone() {
    const requests = [];
    const newItem = [];

    if (
      typeof this.stoneTypeControl.value === 'string' &&
      this.stoneTypeControl.value?.trim()
    ) {
      requests.push(
        this.stoneService.setStoneType({
          stoneTypesID: 0,
          name: this.stoneTypeControl.value.trim(),
        }),
      );
      newItem.push(`Type: ${this.stoneTypeControl.value}`);
    }

    if (
      typeof this.stoneCutControl.value === 'string' &&
      this.stoneCutControl.value?.trim()
    ) {
      requests.push(
        this.stoneService.setStoneCut({
          stoneCutsID: 0,
          name: this.stoneCutControl.value.trim(),
          custom: true,
        }),
      );
      newItem.push(`Cut: ${this.stoneCutControl.value}`);
    }

    if (
      typeof this.stoneClarityControl.value === 'string' &&
      this.stoneClarityControl.value?.trim()
    ) {
      requests.push(
        this.stoneService.setStoneClarity({
          stoneClaritiesID: 0,
          name: this.stoneClarityControl.value.trim(),
        }),
      );
      newItem.push(`Clarity: ${this.stoneClarityControl.value}`);
    }

    if (
      typeof this.stoneColorControl.value === 'string' &&
      this.stoneColorControl.value.trim()
    ) {
      requests.push(
        this.stoneService.setStoneColor({
          stoneColorsID: 0,
          colorName: this.stoneColorControl.value.trim(),
        }),
      );
      newItem.push(`Color: ${this.stoneColorControl.value}`);
    }

    const sizeD1 = this.getValue(this.stoneSizeD1Control, 'dimension_1');
    const sizeD2 = this.getValue(this.stoneSizeD2Control, 'dimension_2');
    const sizeD3 = this.getValue(this.stoneSizeD3Control, 'dimension_3');

    const currentStoneSizesID =
      this.stoneSizes.find(
        (s) =>
          s.dimension_1 === sizeD1 &&
          (s.dimension_2 || 0) === (sizeD2 || 0) &&
          (s.dimension_3 || 0) === (sizeD3 || 0),
      )?.stoneSizesID ?? 0;

    if (currentStoneSizesID === 0) {
      requests.push(
        this.stoneService.setStoneSize({
          stoneSizesID: 0,
          dimension_1: Number(sizeD1 ?? 0),
          dimension_2: Number(sizeD2 ?? 0),
          dimension_3: Number(sizeD3 ?? 0),
        }),
      );
      newItem.push(
        `Size: ${sizeD1}mm ${sizeD2 ? ` x ${sizeD2}mm` : ''}${
          sizeD3 ? ` x ${sizeD3}mm` : ''
        }`,
      );
    }
    if (requests.length > 0) {
      this.showConfirmation(newItem).subscribe((isConfirmed) => {
        if (!isConfirmed) return;
        this.isSaving = true;
        forkJoin(requests).subscribe((response) => {
          const {
            stoneTypesID,
            stoneCutsID,
            stoneClaritiesID,
            stoneColorsID,
            stoneSizesID,
          } = response.reduce<any>((props, res) => {
            props['stoneTypesID'] =
              props?.['stoneTypesID'] ||
              res['stoneTypesID'] ||
              this.getControlValue(this.stoneTypeControl, 'stoneTypesID') ||
              0;
            props['stoneCutsID'] =
              props?.['stoneCutsID'] ||
              res['stoneCutsID'] ||
              this.getControlValue(this.stoneCutControl, 'stoneCutsID') ||
              0;
            props['stoneClaritiesID'] =
              (props?.['stoneClaritiesID'] ||
                res['stoneClaritiesID'] ||
                this.getControlValue(
                  this.stoneClarityControl,
                  'stoneClaritiesID',
                )) ??
              0;
            props['stoneColorsID'] =
              props?.['stoneColorsID'] ||
              res['stoneColorsID'] ||
              this.getControlValue(this.stoneColorControl, 'stoneColorsID') ||
              0;
            props['stoneSizesID'] =
              props?.['stoneSizesID'] ||
              res['stoneSizesID'] ||
              currentStoneSizesID;
            return props;
          }, {});
          this.setStone({
            stoneTypesID,
            stoneCutsID,
            stoneClaritiesID,
            stoneColorsID,
            stoneSizesID,
            caratsPerUnit: this.gem.caratsPerUnit,
          });
        });
      });
    } else {
      const stoneTypesID = this.getControlValue(
        this.stoneTypeControl,
        'stoneTypesID',
      );
      const stoneCutsID = this.getControlValue(
        this.stoneCutControl,
        'stoneCutsID',
      );
      const stoneClaritiesID = this.getControlValue(
        this.stoneClarityControl,
        'stoneClaritiesID',
      );
      const stoneColorsID = this.getControlValue(
        this.stoneColorControl,
        'stoneColorsID',
      );
      const isExist = this.gems.some(
        (gem) =>
          gem.stoneTypesID === stoneTypesID &&
          gem.stoneCutsID === stoneCutsID &&
          gem.stoneClaritiesID === stoneClaritiesID &&
          gem.stoneSizesID === currentStoneSizesID &&
          (!stoneColorsID || gem.stoneColorsID === stoneColorsID) &&
          (!this.gem.avgCarat || gem.avgCarat === this.gem.avgCarat) &&
          gem.labGrown === !!this.gem.labGrown &&
          gem.traceable === !!this.gem.traceable,
      );
      if (isExist) {
        this.confirmationDuplicateStone.fire();
        return;
      }
      this.isSaving = true;
      this.setStone({
        stoneTypesID,
        stoneCutsID,
        stoneClaritiesID,
        stoneSizesID: currentStoneSizesID,
        stoneColorsID,
        caratsPerUnit: this.gem.caratsPerUnit,
        labGrown: this.gem.labGrown,
        traceable: this.gem.traceable,
      });
    }
  }

  setStone({
    stoneTypesID,
    stoneCutsID,
    stoneClaritiesID,
    stoneSizesID,
    stoneColorsID,
    caratsPerUnit,
    stockQTY = 0,
    labGrown = false,
    traceable = false,
  }) {
    this.stoneService
      .setStone({
        stoneTypesID,
        stoneCutsID,
        stoneClaritiesID,
        stoneSizesID,
        stoneColorsID,
        caratsPerUnit,
        stockQTY,
        labGrown,
        traceable,
      })
      .subscribe(({ stonesID }) => {
        this.stoneService.reloadStones(stonesID);
        this.dialogRef.close(true);
        this.isSaving = false;
      });
  }

  displayStoneType(type: StoneType): string {
    return type?.name ?? '';
  }
  private _filterType(name: string): StoneType[] {
    const filterValue = name.toLowerCase();
    return this.stoneTypes.filter((type) =>
      type.name.toLowerCase().includes(filterValue),
    );
  }

  displayStoneCut(cut: StoneCut): string {
    return cut?.name ?? '';
  }
  private _filterCut(name: string): StoneCut[] {
    const filterValue = name.toLowerCase();
    return this.stoneCuts.filter((cut) =>
      cut.name.toLowerCase().includes(filterValue),
    );
  }

  displayStoneClarity(clarity: StoneClarity): string {
    return clarity?.name ?? '';
  }
  private _filterClarity(name: string): StoneClarity[] {
    const filterValue = name.toLowerCase();
    return this.stoneClarities.filter((clarity) =>
      clarity.name.toLowerCase().includes(filterValue),
    );
  }

  displayStoneColor(color: StoneColor): string {
    return color?.colorName ?? '';
  }
  private _filterColor(name: string): StoneColor[] {
    const filterValue = name.toLowerCase();
    return this.stoneColors.filter((color) =>
      color.colorName.toLowerCase().includes(filterValue),
    );
  }

  displayStoneSizeD1(size: StoneSize): string {
    return size?.dimension_1?.toString() ?? '';
  }
  displayStoneSizeD2(size: StoneSize): string {
    return size?.dimension_2?.toString() ?? '';
  }
  displayStoneSizeD3(size: StoneSize): string {
    return size?.dimension_3?.toString() ?? '';
  }

  getValue(ctrl: FormControl, key: string) {
    return typeof ctrl.value === 'string' || typeof ctrl.value === 'number'
      ? ctrl.value ?? ''
      : ctrl.value?.[key] ?? '';
  }

  get getFilteredSizesD1() {
    return orderBy(
      uniqBy(
        this.stoneSizes?.filter((s) => {
          return (
            s.dimension_1 &&
            s.dimension_1
              ?.toString()
              .toLowerCase()
              .includes(this.getValue(this.stoneSizeD1Control, 'dimension_1'))
          );
        }) ?? [],
        'dimension_1',
      ),
      ['dimension_1'],
    );
  }

  get getFilteredSizesD2() {
    return orderBy(
      uniqBy(
        this.stoneSizes?.filter((s) => {
          return (
            s.dimension_1 ===
              this.getValue(this.stoneSizeD1Control, 'dimension_1') &&
            s.dimension_2 &&
            s.dimension_2
              ?.toString()
              .toLowerCase()
              .includes(this.getValue(this.stoneSizeD2Control, 'dimension_2'))
          );
        }) ?? [],
        'dimension_2',
      ),
      ['dimension_2'],
    );
  }

  get getFilteredSizesD3() {
    return orderBy(
      uniqBy(
        this.stoneSizes?.filter((s) => {
          return (
            s.dimension_1 ===
              this.getValue(this.stoneSizeD1Control, 'dimension_1') &&
            s.dimension_2 ===
              this.getValue(this.stoneSizeD2Control, 'dimension_2') &&
            s.dimension_3 &&
            s.dimension_3
              ?.toString()
              .toLowerCase()
              .includes(this.getValue(this.stoneSizeD3Control, 'dimension_3'))
          );
        }) ?? [],
        'dimension_3',
      ),
      ['dimension_3'],
    );
  }

  getFieldBorder(ctrl: FormControl) {
    if (!ctrl.value) return '';
    return typeof ctrl.value === 'string' || typeof ctrl.value === 'number'
      ? 'is-custom'
      : 'is-existing';
  }

  isSizeValid() {
    const d1 = this.getValue(this.stoneSizeD1Control, 'dimension_1');
    const d2 = this.getValue(this.stoneSizeD2Control, 'dimension_2');
    const d3 = this.getValue(this.stoneSizeD3Control, 'dimension_3');

    const scenario1 = d1 && d1 < 1000 && d2 && d2 < 1000 && d3 && d3 < 1000;
    const scenario2 = d1 && d1 < 1000 && d2 && d2 < 1000 && !d3;
    const scenario3 = d1 && d1 < 1000 && !d2 && !d3;
    this.isSizeOrderValid = d1 >= (d2 ?? 0) && (d2 ?? 0) >= (d3 ?? 0);
    return this.isSizeOrderValid && (scenario1 || scenario2 || scenario3);
  }

  checkSize() {
    if (!this.isSizeValid()) {
      this.sizeBorderClass = 'is-invalid';
      return;
    }

    const sizeD1 = this.getValue(this.stoneSizeD1Control, 'dimension_1');
    const sizeD2 = this.getValue(this.stoneSizeD2Control, 'dimension_2');
    const sizeD3 = this.getValue(this.stoneSizeD3Control, 'dimension_3');

    const currentStoneSizesID =
      this.stoneSizes.find(
        (s) =>
          s.dimension_1 === sizeD1 &&
          (s.dimension_2 || 0) === (sizeD2 || 0) &&
          (s.dimension_3 || 0) === (sizeD3 || 0),
      )?.stoneSizesID ?? 0;
    this.sizeBorderClass = currentStoneSizesID
      ? 'is-existing-size'
      : 'is-custom-size';
  }

  get isFormValid() {
    return (
      this.stoneTypeControl.value &&
      this.stoneCutControl.value &&
      this.stoneClarityControl.value &&
      this.isSizeValid() &&
      (!this.gem.avgCarat || this.gem.avgCarat < 1000)
    );
  }

  onTypeCheck() {
    if (
      !this.stoneTypeControl.value ||
      typeof this.stoneTypeControl.value !== 'string'
    )
      return;
    const { value } = this.stoneTypeControl;
    const existingValue = this.stoneTypes.find(
      (i) => i.name?.trim()?.toLowerCase() === value.trim()?.toLowerCase(),
    );
    if (existingValue) {
      this.stoneTypeControl.patchValue({
        stoneTypesID: existingValue.stoneTypesID,
        name: value,
      });
    }
  }

  onCutCheck() {
    if (
      !this.stoneCutControl.value ||
      typeof this.stoneCutControl.value !== 'string'
    )
      return;
    const { value } = this.stoneCutControl;
    const existingValue = this.stoneCuts.find(
      (i) => i.name?.trim()?.toLowerCase() === value.trim()?.toLowerCase(),
    );
    if (existingValue) {
      this.stoneCutControl.patchValue({
        stoneCutsID: existingValue.stoneCutsID,
        name: value,
      });
    }
  }

  onClarityCheck() {
    if (
      !this.stoneClarityControl.value ||
      typeof this.stoneClarityControl.value !== 'string'
    )
      return;
    const { value } = this.stoneClarityControl;
    const existingValue = this.stoneClarities.find(
      (i) => i.name?.trim()?.toLowerCase() === value.trim()?.toLowerCase(),
    );
    if (existingValue) {
      this.stoneClarityControl.patchValue({
        stoneClaritiesID: existingValue.stoneClaritiesID,
        name: value,
      });
    }
  }

  onColorCheck() {
    if (
      !this.stoneColorControl.value ||
      typeof this.stoneColorControl.value !== 'string'
    )
      return;
    const { value } = this.stoneColorControl;
    const existingValue = this.stoneColors.find(
      (i) => i.colorName?.trim()?.toLowerCase() === value.trim()?.toLowerCase(),
    );
    if (existingValue) {
      this.stoneColorControl.patchValue({
        stoneColorsID: existingValue.stoneColorsID,
        colorName: value,
      });
    }
  }

  showConfirmation(data) {
    const dialogRef = this.dialog.open(StoneConfirmationComponent, {
      disableClose: true,
      maxWidth: '500px',
      width: '100%',
      data,
      panelClass: 'stone-dialog-confirmation',
    });
    return dialogRef.afterClosed();
  }

  onGetImage() {
    const stoneType = this.getControlValue(
      this.stoneTypeControl,
      'stoneTypesID',
    );
    const stoneCut = this.getControlValue(this.stoneCutControl, 'stoneCutsID');
    const stoneColor = this.getControlValue(
      this.stoneColorControl,
      'stoneColorsID',
    );
    if (!stoneType || !stoneCut) {
      this.currentStonePicture = '';
      return;
    }
    this.stoneService
      .getStoneDefaultPicture({
        stoneTypesID: stoneType,
        stoneCutsID: stoneCut,
        stoneColorsID: stoneColor || 0,
      })
      .subscribe((data) => {
        if (data.stonePicsDefaultID > 0) {
          this.currentStonePicture = data.picturePath;
        } else {
          this.currentStonePicture = '';
        }
      });
  }
}

import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import '@angular/compiler';
import { enableDebugTools } from '@angular/platform-browser';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

const hostname = window.location.hostname;
const isDevEnvironment = hostname === 'localhost' || hostname === '127.0.0.1';
if (!isDevEnvironment) {
  Sentry.init({
    dsn: environment.sentryDns,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint?.originalException;

      // Exclude padding issue with the ant design menu
      if (
        error instanceof Error &&
        error.message.includes(
          `Previous value for 'padding-left': '48'. Current value: 'null'`,
        )
      ) {
        return null;
      }

      return event;
    },
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((module) => {
    if (!environment.production) {
      const applicationRef = module.injector.get(ApplicationRef);
      const appComponent = applicationRef.components[0];
      enableDebugTools(appComponent);
    }
  })
  .catch((err) => console.error(err));

import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DateTime } from 'luxon';
import { ReportService } from 'src/app/services/report.service';
import { InvoiceFairmined } from './invoice-fairmined';

interface Filters {
  dateRangeFrom?: string;
  dateRangeTo?: string;
}

@Component({
  selector: 'app-invoice-reports',
  templateUrl: './invoice-reports.component.html',
  styleUrls: ['./invoice-reports.component.scss'],
})
export class InvoiceReportsComponent implements OnInit {
  constructor(private reportService: ReportService) {}

  displayedColumns = ['invoicesID'];
  isSaving = false;
  isLoading = false;
  invoices = new MatTableDataSource<InvoiceFairmined>([]);

  filters: Filters = {
    dateRangeFrom: DateTime.local().minus({ months: 9 }).toFormat('yyyy-MM-dd'),
    dateRangeTo: DateTime.local().toFormat('yyyy-MM-dd'),
  };

  ngOnInit(): void {
    this.getInvoiceFairmined();
  }

  getInvoiceFairmined() {
    this.reportService
      .getInvoiceFairmined(this.filters.dateRangeFrom, this.filters.dateRangeTo)
      .subscribe((data) => {
        this.invoices.data = data;
      });
  }

  onDateRangeChange({ start, end }) {
    this.filters.dateRangeFrom = start;
    this.filters.dateRangeTo = end;
    this.getInvoiceFairmined();
  }
}

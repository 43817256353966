<div class="metal-overhead-equations-page">
  <nz-collapse>
    <nz-collapse-panel nzHeader="Metal Market Premiums" [nzActive]="false">
      <div class="metal-market-premiums-container">
        <div class="metal-market-premiums-item silver">
          <label for="premium-silver" style="margin: 0; font-size: 16px"
            >Silver:
          </label>
          <nz-input-number
            name="premium-silver"
            [(ngModel)]="metalMarketPremiums.silver"
            [nzMax]="999.99"
            style="width: 100%"
          ></nz-input-number>
        </div>

        <div class="metal-market-premiums-item gold">
          <label for="premium-gold" style="margin: 0; font-size: 16px"
            >Gold:
          </label>
          <nz-input-number
            name="premium-gold"
            [(ngModel)]="metalMarketPremiums.gold"
            [nzMax]="999.99"
            style="width: 100%"
          ></nz-input-number>
        </div>

        <div class="metal-market-premiums-item platinum">
          <label for="premium-platinum" style="margin: 0; font-size: 16px"
            >Platinum:
          </label>
          <nz-input-number
            name="premium-platinum"
            [(ngModel)]="metalMarketPremiums.platinum"
            [nzMax]="999.99"
            style="width: 100%"
          ></nz-input-number>
        </div>

        <div class="metal-market-premiums-item palladium">
          <label for="premium-palladium" style="margin: 0; font-size: 16px"
            >Palladium:
          </label>
          <nz-input-number
            name="premium-palladium"
            [(ngModel)]="metalMarketPremiums.palladium"
            [nzMax]="999.99"
            style="width: 100%"
          ></nz-input-number>
        </div>

        <div class="metal-market-premiums-item gold">
          <label for="premium-fairmined-gold" style="margin: 0; font-size: 16px"
            >Fairmined Gold:
          </label>
          <nz-input-number
            name="premium-fairmined-gold"
            [(ngModel)]="metalMarketPremiums.fairminedGold"
            [nzMax]="999.99"
            style="width: 100%"
          ></nz-input-number>
        </div>
      </div>
      <div class="save-premiums">
        <button
          class="btn btn-primary"
          [disabled]="isSaving || isLoading"
          (click)="setMetalMarketPremiums()"
        >
          Save Premiums
        </button>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
  <div class="material-calculations">
    <table
      mat-table
      matSort
      [dataSource]="materialCalculations"
      matSortActive="materialCodeId"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Metal</th>
        <td mat-cell *matCellDef="let element">
          {{ element.description }}
        </td>
      </ng-container>
      <ng-container matColumnDef="baseMetal">
        <th mat-header-cell *matHeaderCellDef>Base Metal</th>
        <td mat-cell *matCellDef="let element">
          <nz-select [(ngModel)]="element.baseMetal" style="width: 100%">
            <nz-option [nzValue]="baseMetal.gold" nzLabel="Gold"></nz-option>
            <nz-option
              [nzValue]="baseMetal.fairminedGold"
              nzLabel="Fairmined Gold"
            ></nz-option>
            <nz-option
              [nzValue]="baseMetal.platinum"
              nzLabel="Platinum"
            ></nz-option>
            <nz-option
              [nzValue]="baseMetal.notApplicable"
              nzLabel="N/A"
            ></nz-option>
          </nz-select>
        </td>
      </ng-container>
      <ng-container matColumnDef="preciousPercentage">
        <th mat-header-cell *matHeaderCellDef>Fineness</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.preciousPercentage"
            [disabled]="isSaving"
            [nzMax]="9.9999"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="alloyCost">
        <th mat-header-cell *matHeaderCellDef>Alloy Cost</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.alloyCost"
            [disabled]="isSaving"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="lossFactor">
        <th mat-header-cell *matHeaderCellDef>Loss Factor</th>
        <td mat-cell *matCellDef="let element">
          <nz-input-number
            [(ngModel)]="element.lossFactor"
            [disabled]="isSaving"
            [nzMax]="999.99"
            style="width: 100%"
          ></nz-input-number>
        </td>
      </ng-container>
      <ng-container matColumnDef="pricePerGram">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          Price Per Gram
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: right">
          {{ getPricePerGram(element) | currency }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell text-center" colspan="6">No Available Data</td>
      </tr>
    </table>

    <div style="display: flex; justify-content: flex-end; margin-top: 16px">
      <button
        (click)="onSaveCalculations()"
        class="btn btn-primary"
        style="width: 300px"
        [disabled]="isSaving"
      >
        Save All Calculations
      </button>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { PAGE_NAME } from 'src/app/core/user-permission/user-permission-rules/pages';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';

@Component({
  selector: 'general-reports',
  templateUrl: './general-reports.component.html',
  styleUrls: ['./general-reports.component.scss'],
})
export class GeneralReportsComponent {
  constructor(private userPermissionService: UserPermissionService) {
    this.userPermissionService.checkPagePermission(PAGE_NAME.reports);
  }
}

import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ClickOutsideModule } from 'ng-click-outside';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconDefinition } from '@ant-design/icons-angular';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { RedZoomModule } from 'ngx-red-zoom';

import { NgChartsModule } from 'ng2-charts';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AccountsTableDialogComponent } from './components/accounts-table/accounts-table-dialog/accounts-table-dialog.component';
import { AccountsTableUserComponent } from './components/accounts-table/accounts-table-user/accounts-table-user.component';
import { AccountsTableComponent } from './components/accounts-table/accounts-table.component';
import { ResetPasswordDialogComponent } from './components/accounts-table/reset-password-dialog/reset-password-dialog.component';
import { BillOfMaterialSubItemConfirmationComponent } from './components/bill-of-material-sub-item/bill-of-material-sub-item-confirmation.component';
import { BillOfMaterialSubItemDialogComponent } from './components/bill-of-material-sub-item/bill-of-material-sub-item-dialog..component';
import { BillOfMaterialSubItemComponent } from './components/bill-of-material-sub-item/bill-of-material-sub-item.component';
import { BillOfMaterialComponent } from './components/bill-of-material/bill-of-material.component';
import { BillOfMaterialsAComponent } from './components/bill-of-materials-a/bill-of-materials-a.component';
import { BillOfMaterialsBComponent } from './components/bill-of-materials-b/bill-of-materials-b.component';
import { BillOfMaterialsComponent } from './components/bill-of-materials/bill-of-materials.component';
import { ChainFinishedComponent } from './components/chain-finished/chain-finished.component';
import { ChainTabComponent } from './components/chain-tab/chain-tab.component';
import { ChainComponent } from './components/chain/chain.component';
import { EnamelComponent } from './components/enamel/enamel.component';
import { FindingsComponent } from './components/findings/findings.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { GemsBomComponent } from './components/gems-bom/gems-bom.component';
import { GemsComponent } from './components/gems/gems.component';
import { InventoryLogComponent } from './components/inventory-log/inventory-log.component';
import { ManufacturedMaterialsComponent } from './components/manufactured-materials/manufactured-materials.component';
import { MetalGrainComponent } from './components/metal-grain/metal-grain.component';
import { NonPreciousComponent } from './components/non-precious/non-precious.component';
import { OrderdetailsModule } from './components/orderdetails/orderdetails.module';
import { OrderImportingComponent } from './components/orderimporting/orderimporting.component';
import { OrdersConfirmationComponent } from './components/orders-dialog/orders-confirmation.component';
import { OrdersProgressComponent } from './components/orders-dialog/orders-progress.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderviewComponent } from './components/orderview/orderview.component';
import { ProductGroupTableComponent } from './components/product-group-table/product-group-table.component';
import { ProductReportComponent } from './components/product-report/product-report.component';
import { ProductTableV2Component } from './components/product-table-v2/product-table-v2.component';
import { ProductsTableComponent } from './components/products-table/products-table.component';
import { RawMaterialsComponent } from './components/raw-materials/raw-materials.component';
import { RivaConfirmationComponent } from './components/riva-confirmation/riva-confirmation.component';
import { ConfirmationService } from './components/riva-confirmation/riva-confirmation.service';
import { RivaStlViewerDialogComponent } from './components/riva-stl-viewer/riva-stl-viewer-dialog.component';
import { RivaTextAvatarComponent } from './components/riva-text-avatar/riva-text-avatar.component';
import { SubItemsConfirmationComponent } from './components/sub-items/sub-items-confirmation.component';
import { SubItemsDialogComponent } from './components/sub-items/sub-items-dialog.component';
import { SubItemsComponent } from './components/sub-items/sub-items.component';
import { WorkOrderViewComponent } from './components/work-order-view/work-order-view.component';
import { WorkOrdersReleaseComponent } from './components/work-order-view/work-orders-release.component';
import { WorkOrderComponent } from './components/work-order/work-order.component';
import { AlertModule } from './custom/_alert';
import {
  LoadContentOverlayComponent,
  LoadOverlayComponent,
} from './custom/load-overlay/load-overlay.component';
import { NotificationComponent } from './custom/notification/notification.component';
import { EnumToArrayPipe } from './custom/pipes/enum-to-array.pipe';
import { IdPipe } from './custom/pipes/id.pipe';
import { RivaSideNavComponent } from './custom/riva-side-nav/riva-side-nav.component';
import { GenericService } from './services/generic.service';

import {
  ApiOutline,
  CaretDownFill,
  ControlOutline,
  CreditCardOutline,
  DeleteFill,
  EditFill,
  FileImageOutline,
  FileSearchOutline,
  FileSyncOutline,
  LeftCircleFill,
  LineChartOutline,
  LogoutOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  QrcodeOutline,
  RocketOutline,
  SettingOutline,
  ShopOutline,
  ShoppingCartOutline,
  ToolOutline,
} from '@ant-design/icons-angular/icons';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTabsModule } from 'ng-zorro-antd/tabs';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { Router } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import * as Sentry from "@sentry/angular-ivy";
import { StlModelViewerModule } from 'angular-stl-model-viewer';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { ToastrModule } from 'ngx-toastr';
import { UserPermissionService } from 'src/app/core/user-permission/user-permission.service';
import { UserPermissionDialogComponent } from './components/accounts-table/user-permission-dialog/user-permission-dialog.component';
import { UserPermissionNavigationDialogComponent } from './components/accounts-table/user-permission-navigation-dialog/user-permission-navigation-dialog.component';
import { ProductComponentDialogComponent } from './components/bill-of-material/product-component-dialog/product-component-dialog.component';
import { ProductEnamelComponent } from './components/bill-of-material/product-enamel/product-enamel.component';
import { BomFindingsComponent } from './components/bill-of-material/product-findings/bom-findings/bom-findings.component';
import { BomJumpringComponent } from './components/bill-of-material/product-findings/bom-jumpring/bom-jumpring.component';
import { BomPostComponent } from './components/bill-of-material/product-findings/bom-post/bom-post.component';
import { ProductFindingsTableComponent } from './components/bill-of-material/product-findings/product-findings-table/product-findings-table.component';
import { ProductFindingsComponent } from './components/bill-of-material/product-findings/product-findings.component';
import { ProductLinkBomFormComponent } from './components/bill-of-material/product-link-bom/product-link-bom-form/product-link-bom-form.component';
import { ProductLinkBomTableComponent } from './components/bill-of-material/product-link-bom/product-link-bom-table/product-link-bom-table.component';
import { ProductLinkBomComponent } from './components/bill-of-material/product-link-bom/product-link-bom.component';
import { CustomerAddressDialogComponent } from './components/customer-page/customer-address-dialog/customer-address-dialog.component';
import { CustomerDialogComponent } from './components/customer-page/customer-dialog/customer-dialog.component';
import { CustomerPageComponent } from './components/customer-page/customer-page.component';
import { CustomerSettingDialogComponent } from './components/customer-page/customer-setting-dialog/customer-setting-dialog.component';
import { EquationsComponent } from './components/equations/equations.component';
import { InvoicingShippingComponent } from './components/invoicing-shipping/invoicing-shipping.component';
import { InvoicingDetailDialogComponent } from './components/invoicing-shipping/invoicing/invoicing-detail/invoicing-detail-dialog/invoicing-detail-dialog.component';
import { InvoicingDetailComponent } from './components/invoicing-shipping/invoicing/invoicing-detail/invoicing-detail.component';
import { RelatedShipmentDialogComponent } from './components/invoicing-shipping/invoicing/invoicing-detail/related-shipment-dialog/related-shipment-dialog.component';
import { InvoicingPrintDialogComponent } from './components/invoicing-shipping/invoicing/invoicing-print-dialog/invoicing-print-dialog.component';
import { InvoicingComponent } from './components/invoicing-shipping/invoicing/invoicing.component';
import { ShippingDetailDialogComponent } from './components/invoicing-shipping/shipping/shipping-detail/shipping-detail-dialog/shipping-detail-dialog.component';
import { ShippingDetailComponent } from './components/invoicing-shipping/shipping/shipping-detail/shipping-detail.component';
import { ShippingRelatedInvoiceDialogComponent } from './components/invoicing-shipping/shipping/shipping-detail/shipping-related-invoice-dialog/shipping-related-invoice-dialog.component';
import { ShippingComponent } from './components/invoicing-shipping/shipping/shipping.component';
import { MetalOverheadEquationsComponent } from './components/metal-overhead-equations/metal-overhead-equations.component';
import { OpenOrderReportComponent } from './components/open-order-report/open-order-report.component';
import { OpenOrderStoneReportComponent } from './components/open-order-stone-report/open-order-stone-report.component';
import { StoneReceivingDialogComponent } from './components/open-order-stone-report/stone-receiving-dialog/stone-receiving-dialog.component';
import { StoneReleaseHistoryDialogComponent } from './components/open-order-stone-report/stone-release-history-dialog/stone-release-history-dialog.component';
import { AddProductByCustomerSkuDialogComponent } from './components/orderdetails/list/add-product-by-customer-sku-dialog/add-product-by-customer-sku-dialog.component';
import { OrderUploadDialogComponent } from './components/orderdetails/order-upload-dialog/order-upload-dialog.component';
import { RelatedInvoicesDialogComponent } from './components/orders/related-invoices-dialog/related-invoices-dialog.component';
import { OtherComponentsRoutingCopyDialogComponent } from './components/other-components/other-components-routing/other-components-routing-copy-dialog.component';
import { OtherComponentsRoutingDialogComponent } from './components/other-components/other-components-routing/other-components-routing-dialog.component';
import { OtherComponentsRoutingComponent } from './components/other-components/other-components-routing/other-components-routing.component';
import { OtherComponentsSizeDialogComponent } from './components/other-components/other-components-size-dialog/other-components-size-dialog.component';
import { OtherComponentsSkuGeneratorDialogComponent } from './components/other-components/other-components-sku-generator-dialog/other-components-sku-generator-dialog.component';
import { OtherComponentsComponent } from './components/other-components/other-components.component';
import { CustomerPriceDefaultsComponent } from './components/price-defaults/customer-price-defaults/customer-price-defaults.component';
import { PriceDefaultsComponent } from './components/price-defaults/price-defaults.component';
import { ProductInvoiceDetailsComponent } from './components/product-query/product-invoice-details/product-invoice-details.component';
import { ProductInvoiceComponent } from './components/product-query/product-invoice/product-invoice.component';
import { ProductOrderDetailsComponent } from './components/product-query/product-order-details/product-order-details.component';
import { ProductOrderComponent } from './components/product-query/product-order/product-order.component';
import { ProductQueryComponent } from './components/product-query/product-query.component';
import { ProductRoutingCopyDialogComponent } from './components/product-routing/product-routing-copy-dialog.component';
import { ProductRoutingDialogComponent } from './components/product-routing/product-routing-dialog.component';
import { ProductRoutingComponent } from './components/product-routing/product-routing.component';
import { EnamelConstantComponent } from './components/products-table/enamel-constant/enamel-constant.component';
import { EnamelVariationDialogComponent } from './components/products-table/enamel-variation-dialog/enamel-variation-dialog.component';
import { EnamelVariationComponent } from './components/products-table/enamel-variation/enamel-variation.component';
import { ProductImageOrderDialogComponent } from './components/products-table/product-image-order-dialog/product-image-order-dialog.component';
import { ProductImageComponent } from './components/products-table/product-image/product-image.component';
import { ProductMaterialDialogComponent } from './components/products-table/product-material-dialog/product-material-dialog.component';
import { ProductSettingDialogComponent } from './components/products-table/product-setting-dialog/product-setting-dialog.component';
import { ProductSizesDialogComponent } from './components/products-table/product-sizes-dialog/product-sizes-dialog.component';
import { ProductSkuGeneratorDialogComponent } from './components/products-table/product-sku-generator-dialog/product-sku-generator-dialog.component';
import { GeneralReportsComponent } from './components/reporting/general-reports/general-reports.component';
import { InvoiceReportsComponent } from './components/reporting/general-reports/invoice-reports/invoice-reports.component';
import { ItemWeightsComponent } from './components/reporting/item-weights/item-weights.component';
import { ReportingInvoiceErrorDialogComponent } from './components/reporting/reporting-invoice/reporting-invoice-error-dialog/reporting-invoice-error-dialog.component';
import { ReportingInvoiceComponent } from './components/reporting/reporting-invoice/reporting-invoice.component';
import { ReportingItemPlacedComponent } from './components/reporting/reporting-item-placed/reporting-item-placed.component';
import { ReportingPresetsComponent } from './components/reporting/reporting-presets/reporting-presets.component';
import { ReportingTopProductComponent } from './components/reporting/reporting-top-product/reporting-top-product.component';
import { ReportingTotalShipmentComponent } from './components/reporting/reporting-total-shipment/reporting-total-shipment.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { PDMovementComponent } from './components/reporting/work-order-movement/pd-movement/pd-movement.component';
import { EmployeeDownTimeErrorDialogComponent } from './components/reporting/work-order-movement/work-order-employee-down-time/employee-down-time-error-dialog/employee-down-time-error-dialog.component';
import { WorkOrderEmployeeDownTimeComponent } from './components/reporting/work-order-movement/work-order-employee-down-time/work-order-employee-down-time.component';
import { WorkOrderEmployeeComponent } from './components/reporting/work-order-movement/work-order-employee/work-order-employee.component';
import { WorkOrderIncompleteComponent } from './components/reporting/work-order-movement/work-order-incomplete/work-order-incomplete.component';
import { WorkOrderMovedComponent } from './components/reporting/work-order-movement/work-order-moved/work-order-moved.component';
import { WorkOrderMovementComponent } from './components/reporting/work-order-movement/work-order-movement.component';
import { WorkOrderProcessComparisonComponent } from './components/reporting/work-order-movement/work-order-process-comparison/work-order-process-comparison.component';
import { WorkOrderProductSearchComponent } from './components/reporting/work-order-movement/work-order-product-search/work-order-product-search.component';
import { ChainFinishBomDialogComponent } from './components/riva-chain/riva-chain-finish/chain-finish-bom-dialog/chain-finish-bom-dialog.component';
import { ChainFinishDialogComponent } from './components/riva-chain/riva-chain-finish/chain-finish-dialog/chain-finish-dialog.component';
import { ChainFinishedSkuGeneratorDialogComponent } from './components/riva-chain/riva-chain-finish/chain-finished-sku-generator-dialog/chain-finished-sku-generator-dialog.component';
import { RivaChainFinishListComponent } from './components/riva-chain/riva-chain-finish/riva-chain-finish-list/riva-chain-finish-list.component';
import { RivaChainPageComponent } from './components/riva-chain/riva-chain-page/riva-chain-page.component';
import { ProductChainFinishedTableComponent } from './components/riva-chain/riva-chain-product/product-chain-finished-table/product-chain-finished-table.component';
import { ProductChainUnfinishedTableComponent } from './components/riva-chain/riva-chain-product/product-chain-unfinished-table/product-chain-unfinished-table.component';
import { RivaChainFinishedProductComponent } from './components/riva-chain/riva-chain-product/riva-chain-finished-product/riva-chain-finished-product.component';
import { RivaChainProductContainerComponent } from './components/riva-chain/riva-chain-product/riva-chain-product-container/riva-chain-product-container.component';
import { RivaChainUnfinishedProductComponent } from './components/riva-chain/riva-chain-product/riva-chain-unfinished-product/riva-chain-unfinished-product.component';
import { ChainRoutingCopyDialogComponent } from './components/riva-chain/riva-chain-routing/chain-routing-copy-dialog.component';
import { ChainRoutingDialogComponent } from './components/riva-chain/riva-chain-routing/chain-routing-dialog.component';
import { ChainRoutingComponent } from './components/riva-chain/riva-chain-routing/chain-routing.component';
import { ChainDialogComponent } from './components/riva-chain/riva-chain/chain-dialog/chain-dialog.component';
import { ChainRawDialogComponent } from './components/riva-chain/riva-chain/chain-raw-dialog/chain-raw-dialog.component';
import { ChainUnfinishedSkuGeneratorDialogComponent } from './components/riva-chain/riva-chain/chain-unfinished-sku-generator-dialog/chain-unfinished-sku-generator-dialog.component';
import { RivaChainComponent } from './components/riva-chain/riva-chain/riva-chain.component';
import { RivaDateRangePickerComponent } from './components/riva-date-range-picker/riva-date-range-picker.component';
import { FindingRoutingDialogComponent } from './components/riva-findings/finding-routing/finding-routing-dialog.component';
import { FindingRoutingComponent } from './components/riva-findings/finding-routing/finding-routing.component';
import { FindingSettingDialogComponent } from './components/riva-findings/finding-setting-dialog/finding-setting-dialog.component';
import { FindingStockDialogComponent } from './components/riva-findings/finding-stock-dialog/finding-stock-dialog.component';
import { FindingsDetailsComponent } from './components/riva-findings/findings-details/findings-details.component';
import { FindingsListComponent } from './components/riva-findings/findings-list/findings-list.component';
import { FindingsSkuGeneratorButtonComponent } from './components/riva-findings/findings-sku-generator-button/findings-sku-generator-button.component';
import { FindingsSkuGeneratorDialogComponent } from './components/riva-findings/findings-sku-generator-dialog/findings-sku-generator-dialog.component';
import { FlyerDialogComponent } from './components/riva-findings/flyer/flyer-dialog/flyer-dialog.component';
import { FlyerComponent } from './components/riva-findings/flyer/flyer.component';
import { JumpringDialogComponent } from './components/riva-findings/jumpring/jumpring-dialog/jumpring-dialog.component';
import { JumpringComponent } from './components/riva-findings/jumpring/jumpring.component';
import { LobsterClawDialogComponent } from './components/riva-findings/lobster-claw/lobster-claw-dialog/lobster-claw-dialog.component';
import { LobsterClawComponent } from './components/riva-findings/lobster-claw/lobster-claw.component';
import { PostDialogComponent } from './components/riva-findings/post/post-dialog/post-dialog.component';
import { PostComponent } from './components/riva-findings/post/post.component';
import { RivaFindingsComponent } from './components/riva-findings/riva-findings.component';
import { SpringRingDialogComponent } from './components/riva-findings/spring-ring/spring-ring-dialog/spring-ring-dialog.component';
import { SpringRingComponent } from './components/riva-findings/spring-ring/spring-ring.component';
import { SpringDialogComponent } from './components/riva-findings/spring/spring-dialog/spring-dialog.component';
import { SpringComponent } from './components/riva-findings/spring/spring.component';
import { TagsDialogComponent } from './components/riva-findings/tags/tags-dialog/tags-dialog.component';
import { TagsComponent } from './components/riva-findings/tags/tags.component';
import { StoneConfirmationComponent } from './components/riva-gems/dialogs/stone-confirmation/stone-confirmation.component';
import { StoneDialogComponent } from './components/riva-gems/dialogs/stone-dialog/stone-dialog.component';
import { StoneImagesDialogComponent } from './components/riva-gems/dialogs/stone-images-dialog/stone-images-dialog.component';
import { StoneInventoryAdjustmentDialogComponent } from './components/riva-gems/dialogs/stone-inventory-adjustment-dialog/stone-inventory-adjustment-dialog.component';
import { StoneSizeDialogComponent } from './components/riva-gems/dialogs/stone-size-dialog/stone-size-dialog.component';
import { StoneUpdaterDialogComponent } from './components/riva-gems/dialogs/stone-updater-dialog/stone-updater-dialog.component';
import { GemsDetailsComponent } from './components/riva-gems/gems-details/gems-details.component';
import { GemsListComponent } from './components/riva-gems/gems-list/gems-list.component';
import { RivaGemsComponent } from './components/riva-gems/riva-gems.component';
import { StoneOverrideSizeDialogComponent } from './components/riva-gems/stone-product/stone-override-size-dialog/stone-override-size-dialog.component';
import { StoneOverrideSizeMultipleDialogComponent } from './components/riva-gems/stone-product/stone-override-size-multiple-dialog/stone-override-size-multiple-dialog.component';
import { StoneProductComponent } from './components/riva-gems/stone-product/stone-product.component';
import { RivaTimeoutComponent } from './components/riva-timeout/riva-timeout.component';
import { SupplierDialogComponent } from './components/supplier-page/supplier-dialog/supplier-dialog.component';
import { SupplierPageComponent } from './components/supplier-page/supplier-page.component';
import { WorkOrderLabelPrintingComponent } from './components/work-order-label-printing/work-order-label-printing.component';
import { WorkOrderTrackingComponent } from './components/work-order-tracking/work-order-tracking.component';
import { OrphanWorkOrderDialogComponent } from './components/work-order-view/orphan-work-order-dialog/orphan-work-order-dialog.component';
import { ReceivingItemDialogComponent } from './components/work-order-view/receiving-item-dialog/receiving-item-dialog.component';
import { WorkOrderPrintDialogComponent } from './components/work-order-view/work-order-print/work-order-print-dialog.component';
import { authenticationInterceptorProviders } from './core/authentication/authentication.interceptor';
import { AuthenticationService } from './core/authentication/authentication.service';
import { NoCommaPipe } from './custom/pipes/no-comma.pipe';
import { SharedService } from './services/shared.service';
import { SharedModule } from './shared modules/shared.module';

const icons: IconDefinition[] = [
  MenuFoldOutline,
  MenuUnfoldOutline,
  SettingOutline,
  ShopOutline,
  ControlOutline,
  ToolOutline,
  LineChartOutline,
  CreditCardOutline,
  RocketOutline,
  ApiOutline,
  FileSyncOutline,
  ShoppingCartOutline,
  LogoutOutline,
  DeleteFill,
  LeftCircleFill,
  FileImageOutline,
  EditFill,
  CaretDownFill,
  QrcodeOutline,
  FileSearchOutline,
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotificationComponent,
    LoadOverlayComponent,
    LoadContentOverlayComponent,
    OrderImportingComponent,
    InventoryLogComponent,
    ProductsTableComponent,
    EnumToArrayPipe,
    GemsComponent,
    ProductTableV2Component,
    ProductGroupTableComponent,
    RawMaterialsComponent,
    EnamelComponent,
    BillOfMaterialsBComponent,
    BillOfMaterialsAComponent,
    MetalGrainComponent,
    FindingsComponent,
    NonPreciousComponent,
    ManufacturedMaterialsComponent,
    ChainTabComponent,
    ChainFinishedComponent,
    ChainComponent,
    GemsBomComponent,
    BillOfMaterialsComponent,
    OrdersComponent,
    ForbiddenComponent,
    ProductReportComponent,
    WorkOrderComponent,
    OrderviewComponent,
    WorkOrderViewComponent,
    IdPipe,
    OrdersConfirmationComponent,
    OrdersProgressComponent,
    WorkOrdersReleaseComponent,
    SubItemsComponent,
    SubItemsDialogComponent,
    SubItemsConfirmationComponent,
    BillOfMaterialComponent,
    BillOfMaterialSubItemComponent,
    BillOfMaterialSubItemDialogComponent,
    BillOfMaterialSubItemConfirmationComponent,
    AccountsTableUserComponent,
    AccountsTableDialogComponent,
    RivaConfirmationComponent,
    ResetPasswordDialogComponent,
    AccountsTableComponent,
    RivaTextAvatarComponent,
    RivaStlViewerDialogComponent,
    RivaSideNavComponent,
    EnamelVariationDialogComponent,
    EnamelVariationComponent,
    ProductRoutingDialogComponent,
    ProductRoutingComponent,
    ProductComponentDialogComponent,
    ProductEnamelComponent,
    EnamelConstantComponent,
    SupplierPageComponent,
    SupplierDialogComponent,
    CustomerPageComponent,
    CustomerDialogComponent,
    InvoicingShippingComponent,
    RivaFindingsComponent,
    FindingsListComponent,
    FindingsDetailsComponent,
    ProductFindingsComponent,
    ProductFindingsTableComponent,
    RivaChainComponent,
    RivaChainPageComponent,
    ProductRoutingCopyDialogComponent,
    ProductImageComponent,
    ProductImageOrderDialogComponent,
    UserPermissionDialogComponent,
    InvoicingComponent,
    InvoicingDetailComponent,
    InvoicingDetailDialogComponent,
    ShippingComponent,
    ShippingDetailComponent,
    ShippingDetailDialogComponent,
    InvoicingPrintDialogComponent,
    NoCommaPipe,
    RelatedInvoicesDialogComponent,
    GemsListComponent,
    RivaGemsComponent,
    GemsDetailsComponent,
    StoneSizeDialogComponent,
    StoneDialogComponent,
    StoneConfirmationComponent,
    StoneProductComponent,
    StoneImagesDialogComponent,
    WorkOrderPrintDialogComponent,
    ProductSettingDialogComponent,
    ReceivingItemDialogComponent,
    RelatedShipmentDialogComponent,
    ShippingRelatedInvoiceDialogComponent,
    OrphanWorkOrderDialogComponent,
    StoneOverrideSizeDialogComponent,
    JumpringComponent,
    JumpringDialogComponent,
    PostComponent,
    PostDialogComponent,
    CustomerAddressDialogComponent,
    BomJumpringComponent,
    BomPostComponent,
    LobsterClawComponent,
    LobsterClawDialogComponent,
    SpringRingComponent,
    SpringRingDialogComponent,
    ChainDialogComponent,
    ChainRawDialogComponent,
    RivaChainFinishListComponent,
    ChainFinishDialogComponent,
    ChainFinishBomDialogComponent,
    RivaChainUnfinishedProductComponent,
    RivaChainProductContainerComponent,
    ProductChainUnfinishedTableComponent,
    RivaChainFinishedProductComponent,
    ProductChainFinishedTableComponent,
    ChainRoutingComponent,
    ChainRoutingDialogComponent,
    ChainRoutingCopyDialogComponent,
    ProductSkuGeneratorDialogComponent,
    ChainUnfinishedSkuGeneratorDialogComponent,
    ChainFinishedSkuGeneratorDialogComponent,
    TagsComponent,
    TagsDialogComponent,
    SpringComponent,
    SpringDialogComponent,
    ProductMaterialDialogComponent,
    FlyerComponent,
    FlyerDialogComponent,
    FindingRoutingDialogComponent,
    FindingRoutingComponent,
    FindingStockDialogComponent,
    FindingsSkuGeneratorDialogComponent,
    FindingsSkuGeneratorButtonComponent,
    BomFindingsComponent,
    FindingSettingDialogComponent,
    OtherComponentsComponent,
    OtherComponentsRoutingDialogComponent,
    OtherComponentsRoutingComponent,
    OtherComponentsRoutingCopyDialogComponent,
    OtherComponentsSizeDialogComponent,
    AddProductByCustomerSkuDialogComponent,
    OpenOrderReportComponent,
    ReportingComponent,
    ReportingTotalShipmentComponent,
    RivaDateRangePickerComponent,
    ReportingItemPlacedComponent,
    ReportingTopProductComponent,
    CustomerSettingDialogComponent,
    ReportingPresetsComponent,
    OpenOrderStoneReportComponent,
    ItemWeightsComponent,
    StoneInventoryAdjustmentDialogComponent,
    StoneReceivingDialogComponent,
    StoneUpdaterDialogComponent,
    OtherComponentsSkuGeneratorDialogComponent,
    ReportingInvoiceComponent,
    StoneReleaseHistoryDialogComponent,
    WorkOrderTrackingComponent,
    RivaTimeoutComponent,
    ProductLinkBomFormComponent,
    ProductLinkBomTableComponent,
    ProductLinkBomComponent,
    WorkOrderMovementComponent,
    WorkOrderMovedComponent,
    WorkOrderProductSearchComponent,
    WorkOrderIncompleteComponent,
    WorkOrderEmployeeComponent,
    WorkOrderLabelPrintingComponent,
    WorkOrderProcessComparisonComponent,
    WorkOrderEmployeeDownTimeComponent,
    StoneOverrideSizeMultipleDialogComponent,
    UserPermissionNavigationDialogComponent,
    OrderUploadDialogComponent,
    EmployeeDownTimeErrorDialogComponent,
    ReportingInvoiceErrorDialogComponent,
    ProductQueryComponent,
    ProductOrderComponent,
    ProductInvoiceComponent,
    ProductOrderDetailsComponent,
    ProductInvoiceDetailsComponent,
    PDMovementComponent,
    ProductSizesDialogComponent,
    EquationsComponent,
    PriceDefaultsComponent,
    CustomerPriceDefaultsComponent,
    MetalOverheadEquationsComponent,
    InvoiceReportsComponent,
    GeneralReportsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatSidenavModule,
    AlertModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    NgbModule,
    ClickOutsideModule,
    Ng2SearchPipeModule,
    MatTabsModule,
    IvyCarouselModule,
    // QRCodeModule,
    MatAutocompleteModule,
    MatBadgeModule,
    OrderdetailsModule,
    NgxMatSelectSearchModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatPaginatorModule,
    MatIconModule,
    MatExpansionModule,
    MatMenuModule,
    MatDividerModule,
    MatSnackBarModule,
    MatRadioModule,
    NzLayoutModule,
    NzMenuModule,
    NzButtonModule,
    NzToolTipModule,
    StlModelViewerModule,
    DragDropModule,
    SlickCarouselModule,
    RedZoomModule,
    NzInputModule,
    NzSelectModule,
    NzInputNumberModule,
    NzTabsModule,
    NzCollapseModule,
    NzPopoverModule,
    NgxGalleryModule,
    PdfViewerModule,
    NzSwitchModule,
    NzAlertModule,
    NgChartsModule,
    NzIconModule.forRoot(icons),
    SweetAlert2Module.forRoot(),
    SharedModule,
    ToastrModule.forRoot(),
    NzProgressModule,
    MatButtonToggleModule,
    NgIdleKeepaliveModule.forRoot(),
    NgxFileDropModule,
  ],
  exports: [EnumToArrayPipe, CurrencyPipe],
  providers: [
    GenericService,
    DatePipe,
    IdPipe,
    ConfirmationService,
    SharedService,
    AuthenticationService,
    UserPermissionService,
    ...authenticationInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
